import { Flex, FlexProps } from 'cdk'
import {
  BORDER_WIDTH_0_5,
  BorderRadiusName,
  COLOR_LIGHTER,
  COLOR_NEUTRAL_30,
  COLOR_NEUTRAL_40,
  COLOR_NEUTRAL_70,
  COLOR_VIOLET_50,
  COLOR_VIOLET_70,
  cssvarBorderRadius,
  cssvarFontFamily,
  cssvarFontSize,
  cssvarFontWeight,
  cssvarLetterSpacing,
  cssvarLineHeight,
  cssvarSpacing,
  FontSizeName,
  LineHeightName,
  SPACING_3XS,
  SpacingName,
} from 'design-tokens'
import { forwardRef, PropsWithChildren, ReactNode, useMemo } from 'react'
import styled, { css } from 'styled-components'
import { Hint } from '.'
import { Checkbox, CheckboxProps } from './Checkbox'

const sizeNames = ['xs', 'sm', 'md', 'lg'] as const

type SizeName = (typeof sizeNames)[number]

export type CheckboxButtonProps = CheckboxProps &
  PropsWithChildren<{
    sizeName?: SizeName
    hint?: ReactNode

    /** Use `name` for `aria-labelledby` @see https://www.w3.org/WAI/tutorials/forms/instructions/ */
    name?: string
  }>

type CheckboxButtonStyle = {
  borderRadius: BorderRadiusName
  fontSize: FontSizeName
  gap: SpacingName
  lineHeight: LineHeightName
  px: SpacingName
  py: SpacingName
}

export const checkboxButtonSizesStyles: Record<SizeName, CheckboxButtonStyle> = {
  xs: { borderRadius: '2xs', fontSize: '14', gap: '2xs', lineHeight: '16', px: '2xs', py: '3xs' },
  sm: { borderRadius: '2xs', fontSize: '16', gap: '2xs', lineHeight: '24', px: 'xs', py: '3xs' },
  md: { borderRadius: 'xs', fontSize: '18', gap: 'xs', lineHeight: '24', px: 'sm', py: 'xs' },
  lg: { borderRadius: 'xs', fontSize: '20', gap: 'xs', lineHeight: '24', px: 'sm', py: 'sm' },
}

type CheckboxLabelProps = FlexProps & {
  $size: SizeName
}

const CheckboxLabel = styled(Flex).withConfig({ displayName: 'CheckboxButtonLabel' })<CheckboxLabelProps>`
  ${({ $size }) => css`
    font-weight: ${cssvarFontWeight('400')};
    font-size: ${cssvarFontSize(checkboxButtonSizesStyles[$size].fontSize)};
    font-family: ${cssvarFontFamily('degular-text')};
    line-height: ${cssvarLineHeight(checkboxButtonSizesStyles[$size].lineHeight)};
    letter-spacing: calc(${cssvarLetterSpacing('040')} * -1);
  `}
  background-color: ${COLOR_LIGHTER};
  cursor: pointer;

  &:has(:disabled) {
    border-color: ${COLOR_NEUTRAL_40};
    background-color: ${COLOR_NEUTRAL_30};
    color: ${COLOR_NEUTRAL_70};
    cursor: not-allowed;
  }

  &:hover:has(:enabled) {
    color: ${COLOR_VIOLET_70};
    box-shadow: 0 0 0 4px rgb(from ${COLOR_VIOLET_50} r g b / 24%);
  }

  &:has(:checked):has(:enabled) {
    border-color: ${COLOR_VIOLET_70};
    background-color: ${COLOR_NEUTRAL_30};

    &:hover {
      background-color: ${COLOR_LIGHTER};
    }
  }
`

export const CheckboxButton = forwardRef<HTMLInputElement, CheckboxButtonProps>(
  ({ children, hint, sizeName = 'sm', ...checkboxProps }, ref) => {
    const { borderRadius, gap, px, py } = useMemo(() => {
      const { borderRadius, gap, px, py } = checkboxButtonSizesStyles[sizeName]

      return {
        gap: cssvarSpacing(gap),
        borderRadius: cssvarBorderRadius(borderRadius),
        px: cssvarSpacing(px),
        py: cssvarSpacing(py),
      }
    }, [sizeName])

    const hintId = useMemo(
      () => (hint && checkboxProps.name ? `hint-${checkboxProps.name}` : undefined),
      [checkboxProps.name, hint],
    )

    const identifier = useMemo(
      () => `${checkboxProps.name}-${checkboxProps.value}`,
      [checkboxProps.name, checkboxProps.value],
    )

    return (
      <Flex $gap={SPACING_3XS}>
        <CheckboxLabel
          $align="center"
          $borderColorName="neutral-40"
          $borderRadius={borderRadius}
          $borderSize={BORDER_WIDTH_0_5}
          $direction="row"
          $gap={gap}
          $px={px}
          $py={py}
          $size={sizeName}
          as="label"
          htmlFor={identifier}
        >
          <Flex>
            <Checkbox ref={ref} id={identifier} {...checkboxProps} aria-labelledby={hintId} />
          </Flex>
          {children}
        </CheckboxLabel>
        {hint && (
          <Flex $px={px}>
            <Hint id={hintId}>{hint}</Hint>
          </Flex>
        )}
      </Flex>
    )
  },
)
