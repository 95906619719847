import {
  BORDER_RADIUS_CIRCLE,
  BORDER_WIDTH_1,
  COLOR_DARKER,
  COLOR_GREY_20,
  COLOR_GREY_40,
  COLOR_LIGHTER,
  COLOR_VIOLET_50,
  COLOR_WHITE,
  TIME_150,
} from 'design-tokens'
import { forwardRef, InputHTMLAttributes } from 'react'
import styled from 'styled-components'

const Container = styled.div.withConfig({ displayName: 'Radio' })`
  position: relative;
  width: 16px;
  height: 16px;

  .srns-dark & {
    color: ${COLOR_DARKER};
  }

  svg {
    position: absolute;
    inset: 0;
    vertical-align: top;
    pointer-events: none;
  }

  &:has(:disabled) {
    color: ${COLOR_GREY_40};
  }

  &:has(:checked) {
    color: ${COLOR_WHITE};

    path {
      stroke-dashoffset: 35;
    }
  }

  &:not(:has(:checked)) {
    svg {
      visibility: hidden;
    }
  }
`

const Input = styled.input.withConfig({ displayName: 'RadioInput' })`
  position: absolute;
  inset: 0;
  margin: 0;
  border: ${BORDER_WIDTH_1} solid ${COLOR_VIOLET_50};
  border-radius: ${BORDER_RADIUS_CIRCLE};
  background-color: ${COLOR_LIGHTER};
  cursor: pointer;
  transition: background-color ${TIME_150} ease;
  appearance: none;

  &:disabled {
    border-color: ${COLOR_GREY_40};
    background-color: ${COLOR_GREY_20};
    cursor: not-allowed;
  }

  &:checked {
    background-color: ${COLOR_VIOLET_50};

    &:disabled {
      background-color: ${COLOR_GREY_40};
    }
  }
`

const Sign = () => (
  <svg aria-hidden="true" focusable="false" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" fill="currentColor" r="6" />
  </svg>
)

export type RadioProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> & {
  /** Use `name` for `aria-labelledby` @see https://www.w3.org/WAI/tutorials/forms/instructions/ */
  name?: string
}

export const Radio = forwardRef<HTMLInputElement, RadioProps>((props, ref) => (
  <Container>
    <Input ref={ref} aria-checked={props.checked} aria-disabled={props.disabled} type="radio" {...props} />
    <Sign />
  </Container>
))
