/**
 * THIS IS AN AUTOGENERATED FILE.
 * DO NOT EDIT THIS FILE DIRECTLY.
 */

/** @deprecated Use new token `COLOR_GREY_80_VALUE` */
export const COLOR_GREY_11_VALUE = '#82828F' as const

/** @deprecated Use new token `COLOR_GREY_60_VALUE` */
export const COLOR_GREY_08_VALUE = '#B6B6C1' as const

/** @deprecated Use new token `COLOR_GREY_50_VALUE` */
export const COLOR_GREY_06_VALUE = '#C8C8D2' as const

/** @deprecated Use new token `COLOR_GREY_40_VALUE` */
export const COLOR_GREY_04_VALUE = '#E0E0E7' as const

/** @deprecated Use new token `COLOR_GREY_30_VALUE` */
export const COLOR_GREY_02_VALUE = '#F0EFF4' as const

/** @deprecated Use new token `COLOR_DARKER_VALUE` */
export const COLOR_BLACK_VALUE = '#141323' as const

/** @deprecated Use new token `COLOR_LIGHTER_VALUE` */
export const COLOR_WHITE_VALUE = '#ffffff' as const

export const COLOR_PRIMARY_VALUE = '#5039D8' as const

export const COLOR_LIGHTER_VALUE = '#ffffff' as const

export const COLOR_DARKER_VALUE = '#141323' as const

export const COLOR_SUCCESS_VALUE = '#12979E' as const

export const COLOR_INFO_VALUE = '#0A84FF' as const

export const COLOR_WARNING_VALUE = '#E1AF0F' as const

export const COLOR_ERROR_VALUE = '#FF545B' as const

/** @deprecated Use new token `COLOR_VIOLET_80_VALUE` */
export const COLOR_PURPLE_10_VALUE = '#382897' as const

/** @deprecated Use new token `COLOR_VIOLET_50_VALUE` */
export const COLOR_PURPLE_08_VALUE = '#5039D8' as const

/** @deprecated Use new token `COLOR_VIOLET_40_VALUE` */
export const COLOR_PURPLE_06_VALUE = '#B5B2EA' as const

/** @deprecated Use new token `COLOR_VIOLET_30_VALUE` */
export const COLOR_PURPLE_04_VALUE = '#DFDAF6' as const

/** @deprecated Use new token `COLOR_VIOLET_10_VALUE` */
export const COLOR_PURPLE_02_VALUE = '#F2F2FF' as const

/** @deprecated Use new token `COLOR_GREEN_80_VALUE` */
export const COLOR_GREEN_10_VALUE = '#0D6A6F' as const

export const COLOR_GREEN_20_VALUE = '#C3E3DF' as const

export const COLOR_GREEN_40_VALUE = '#81CCCA' as const

export const COLOR_GREEN_60_VALUE = '#12979E' as const

export const COLOR_GREEN_80_VALUE = '#0D6A6F' as const

/** @deprecated Use new token `COLOR_GREEN_60_VALUE` */
export const COLOR_GREEN_08_VALUE = '#12979E' as const

/** @deprecated Use new token `COLOR_GREEN_40_VALUE` */
export const COLOR_GREEN_06_VALUE = '#81CCCA' as const

/** @deprecated Use new token `COLOR_GREEN_20_VALUE` */
export const COLOR_GREEN_04_VALUE = '#C3E3DF' as const

/** @deprecated Use new token `COLOR_GREEN_20_VALUE` */
export const COLOR_GREEN_02_VALUE = '#EBF4F4' as const

/** @deprecated Use new token `COLOR_RED_80_VALUE` */
export const COLOR_CORAL_10_VALUE = '#B33B40' as const

/** @deprecated Use new token `COLOR_RED_60_VALUE` */
export const COLOR_CORAL_08_VALUE = '#FF545B' as const

/** @deprecated Use new token `COLOR_RED_40_VALUE` */
export const COLOR_CORAL_06_VALUE = '#FFAEB4' as const

/** @deprecated Use new token `COLOR_RED_20_VALUE` */
export const COLOR_CORAL_04_VALUE = '#FFDADE' as const

/** @deprecated Use new token `COLOR_RED_20_VALUE` */
export const COLOR_CORAL_02_VALUE = '#FAF1F3' as const

/** @deprecated Use new token `COLOR_YELLOW_80_VALUE` */
export const COLOR_YELLOW_10_VALUE = '#B48C0C' as const

export const COLOR_YELLOW_20_VALUE = '#F7E7B7' as const

export const COLOR_YELLOW_40_VALUE = '#ECCE6F' as const

export const COLOR_YELLOW_60_VALUE = '#E1AF0F' as const

export const COLOR_YELLOW_80_VALUE = '#B48C0C' as const

/** @deprecated Use new token `COLOR_YELLOW_60_VALUE` */
export const COLOR_YELLOW_08_VALUE = '#E1AF0F' as const

/** @deprecated Use new token `COLOR_YELLOW_40_VALUE` */
export const COLOR_YELLOW_06_VALUE = '#ECCE6F' as const

/** @deprecated Use new token `COLOR_YELLOW_20_VALUE` */
export const COLOR_YELLOW_04_VALUE = '#F7E7B7' as const

/** @deprecated Use new token `COLOR_YELLOW_20_VALUE` */
export const COLOR_YELLOW_02_VALUE = '#FEFCEF' as const

export const COLOR_ROLE_PRIMARY_VALUE = '#5039D8' as const

export const COLOR_ROLE_SUCCESS_VALUE = '#12979E' as const

export const COLOR_ROLE_WARNING_VALUE = '#E1AF0F' as const

export const COLOR_ROLE_ERROR_VALUE = '#FF545B' as const

export const COLOR_ROLE_BACKGROUND_VALUE = '#ffffff' as const

export const COLOR_NEUTRAL_10_VALUE = '#ffffff' as const

export const COLOR_NEUTRAL_20_VALUE = '#F0EFF4' as const

export const COLOR_NEUTRAL_30_VALUE = '#F0EFF4' as const

export const COLOR_NEUTRAL_40_VALUE = '#E0E0E7' as const

export const COLOR_NEUTRAL_50_VALUE = '#C8C8D2' as const

export const COLOR_NEUTRAL_60_VALUE = '#B6B6C1' as const

export const COLOR_NEUTRAL_70_VALUE = '#B6B6C1' as const

export const COLOR_NEUTRAL_80_VALUE = '#82828F' as const

export const COLOR_NEUTRAL_90_VALUE = '#82828F' as const

export const COLOR_NEUTRAL_100_VALUE = '#82828F' as const

export const COLOR_NEUTRAL_110_VALUE = '#141323' as const

export const COLOR_NEUTRAL_120_VALUE = '#141323' as const

export const COLOR_VIOLET_10_VALUE = '#F2F2FF' as const

export const COLOR_VIOLET_20_VALUE = '#F2F2FF' as const

export const COLOR_VIOLET_30_VALUE = '#DFDAF6' as const

export const COLOR_VIOLET_40_VALUE = '#B5B2EA' as const

export const COLOR_VIOLET_50_VALUE = '#5039D8' as const

export const COLOR_VIOLET_60_VALUE = '#5039D8' as const

export const COLOR_VIOLET_70_VALUE = '#5039D8' as const

export const COLOR_VIOLET_80_VALUE = '#382897' as const

export const COLOR_BLUE_20_VALUE = '#DBEDFF' as const

export const COLOR_BLUE_40_VALUE = '#70C6FF' as const

export const COLOR_BLUE_60_VALUE = '#0A84FF' as const

export const COLOR_BLUE_80_VALUE = '#0A4299' as const

export const COLOR_RED_20_VALUE = '#FFDADE' as const

export const COLOR_RED_40_VALUE = '#FFAEB4' as const

export const COLOR_RED_60_VALUE = '#FF545B' as const

export const COLOR_RED_80_VALUE = '#B33B40' as const
