/**
 * THIS IS AN AUTOGENERATED FILE.
 * DO NOT EDIT THIS FILE DIRECTLY.
 */

import { FontFamilyName } from '../types/font-family'
import { cssvar, cssvarName, cssvarValue } from '../utils/cssvar'

export const DESIGN_TOKENS_FONT_FAMILY_PREFIX = 'font-family'

export const cssvarNameFontFamily = (fontFamily: FontFamilyName) =>
  cssvarName(`${DESIGN_TOKENS_FONT_FAMILY_PREFIX}-${fontFamily}`)

export const cssvarValueFontFamily = (fontFamily: FontFamilyName) =>
  cssvarValue(`${DESIGN_TOKENS_FONT_FAMILY_PREFIX}-${fontFamily}`)

export const cssvarFontFamily = (fontFamily: FontFamilyName) =>
  cssvar(`${DESIGN_TOKENS_FONT_FAMILY_PREFIX}-${fontFamily}`)
