import {
  FloatingFocusManager,
  FloatingPortal,
  FloatingOverlay as FloatingUiFloatingOverlay,
  useClick,
  useDismiss,
  useFloating,
  useId,
  useInteractions,
  useMergeRefs,
  useRole,
  useTransitionStyles,
} from '@floating-ui/react'
import { media } from 'cdk'
import {
  BORDER_RADIUS_0,
  BORDER_WIDTH_0,
  BORDER_WIDTH_1,
  COLOR_BLACK,
  COLOR_LIGHTER,
  COLOR_VIOLET_40,
  ELEVATION_SM,
  OPACITY_48,
  SPACING_0,
  SPACING_LG,
  SPACING_XS,
  TIME_300_VALUE,
} from 'design-tokens'
import { Icon } from 'icons'
import {
  ButtonHTMLAttributes,
  Dispatch,
  HTMLProps,
  PropsWithChildren,
  ReactElement,
  SetStateAction,
  cloneElement,
  createContext,
  forwardRef,
  isValidElement,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import styled from 'styled-components'
import { Button } from './Button'
import { Card } from './Card'
import { Text, TextProps } from './Text'

export type ModalOptions = {
  initialIsOpen?: boolean
  isOpen?: boolean
  onIsOpenChange?: (isOpen: boolean) => void
}

export function useModal({
  initialIsOpen = false,
  isOpen: controlledIsOpen,
  onIsOpenChange: setControlledIsOpen,
}: ModalOptions) {
  const [uncontrolledIsOpen, setUncontrolledIsOpen] = useState(initialIsOpen)
  const [labelId, setLabelId] = useState<string | undefined>()
  const [descriptionId, setDescriptionId] = useState<string | undefined>()

  const isOpen = controlledIsOpen ?? uncontrolledIsOpen
  const setIsOpen = setControlledIsOpen ?? setUncontrolledIsOpen

  const data = useFloating({ open: isOpen, onOpenChange: setIsOpen })
  const context = data.context

  const click = useClick(context, { enabled: controlledIsOpen == null })
  const dismiss = useDismiss(context, { outsidePressEvent: 'mousedown' })
  const role = useRole(context)
  const interactions = useInteractions([click, dismiss, role])

  const close = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const { isMounted, styles: modalContainerStyles } = useTransitionStyles(context, {
    duration: parseInt(TIME_300_VALUE),
    initial: { translate: '0 100%', filter: 'blur(3.5px)' },
  })
  const { styles: floatingOverlayStyles } = useTransitionStyles(context, {
    initial: { opacity: '0' },
  })

  return useMemo(
    () => ({
      close,
      isOpen,
      setIsOpen,
      ...interactions,
      ...data,
      isMounted,
      modalContainerStyles,
      floatingOverlayStyles,
      labelId,
      descriptionId,
      setLabelId,
      setDescriptionId,
    }),
    [
      close,
      isOpen,
      setIsOpen,
      interactions,
      data,
      isMounted,
      modalContainerStyles,
      floatingOverlayStyles,
      labelId,
      descriptionId,
    ],
  )
}

type ContextType =
  | (ReturnType<typeof useModal> & {
      setLabelId: Dispatch<SetStateAction<string | undefined>>
      setDescriptionId: Dispatch<SetStateAction<string | undefined>>
    })
  | null

const ModalContext = createContext<ContextType>(null)

export const useModalContext = () => {
  const context = useContext(ModalContext)

  if (context === null) {
    throw new Error('Modal components must be wrapped in <Modal />')
  }

  return context
}

const ModalCloseContainer = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  justify-content: flex-end;
  padding: ${SPACING_XS} ${SPACING_LG};
  border-bottom: ${BORDER_WIDTH_1} solid ${[COLOR_VIOLET_40]};
  background-color: ${COLOR_LIGHTER};
`

const ModalClose = forwardRef<HTMLButtonElement, ButtonHTMLAttributes<HTMLButtonElement>>((props, ref) => {
  const { close } = useModalContext()

  return (
    <Button
      {...props}
      ref={ref}
      aria-label="xmark"
      isRound={true}
      kind="secondary"
      onClick={close}
      size="sm"
      type="button"
    >
      <Icon name="xmark" size={20} />
    </Button>
  )
})

type ModalTriggerProps = PropsWithChildren

export const ModalTrigger = forwardRef<HTMLButtonElement, HTMLProps<HTMLButtonElement> & ModalTriggerProps>(
  ({ children, ...props }, propRef) => {
    const { getReferenceProps, isOpen, refs } = useModalContext()
    const childrenRef = (children as any).ref
    const ref = useMergeRefs([refs.setReference, propRef, childrenRef])
    const otherProps = {
      'data-state': isOpen ? 'open' : 'closed',
    }

    if (isValidElement(children)) {
      return cloneElement(
        children,
        getReferenceProps({
          ref,
          ...props,
          ...children.props,
          ...otherProps,
        }),
      )
    }

    return (
      <button ref={ref} {...otherProps} {...getReferenceProps(props)}>
        {children}
      </button>
    )
  },
)

export const ModalTop = forwardRef<HTMLElement, Partial<TextProps> & HTMLProps<HTMLElement>>(
  ({ children, ...props }, propRef) => {
    const { getItemProps } = useModalContext()
    const id = useId()
    const ref = useMergeRefs([propRef])
    const itemProps = getItemProps(props)

    return (
      <Text as="p" kind="paragraph" {...itemProps} ref={ref} id={id}>
        {children}
      </Text>
    )
  },
)

export const ModalTitle = forwardRef<HTMLElement, Partial<TextProps> & HTMLProps<HTMLElement>>(
  ({ children, ...props }, propRef) => {
    const { getItemProps, setLabelId } = useModalContext()
    const id = useId()
    const ref = useMergeRefs([propRef])
    const itemProps = getItemProps(props)

    useLayoutEffect(() => {
      setLabelId(id)

      return () => setLabelId(undefined)
    }, [id, setLabelId])

    return (
      <Text as="h2" fontWeight="600" kind="h1" {...itemProps} ref={ref} id={id}>
        {children}
      </Text>
    )
  },
)

export const ModalDescription = forwardRef<HTMLElement, Partial<TextProps> & HTMLProps<HTMLElement>>(
  ({ children, ...props }, propRef) => {
    const { getItemProps, setDescriptionId } = useModalContext()
    const id = useId()
    const ref = useMergeRefs([propRef])
    const itemProps = getItemProps(props)

    useLayoutEffect(() => {
      setDescriptionId(id)

      return () => setDescriptionId(undefined)
    }, [id, setDescriptionId])

    return (
      <Text as="p" kind="paragraph" {...itemProps} ref={ref} id={id}>
        {children}
      </Text>
    )
  },
)

// Note: Modals, Popovers, Tooltips, SelectNew must have the same zIndex.
const FloatingOverlay = styled(FloatingUiFloatingOverlay)`
  z-index: 102;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  ${media.gtSm`
    align-items: center;
    justify-content: center;
  `}

  &::before {
    content: '';
    position: fixed;
    inset: 0;
    background-color: ${COLOR_BLACK};
    opacity: ${OPACITY_48};
  }
`

const ModalContainer = styled(Card)`
  position: fixed;
  z-index: 102;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  max-height: calc(100vh - 100px);
  padding: ${SPACING_0};
  border: ${BORDER_WIDTH_0};
  box-shadow: ${ELEVATION_SM};

  ${media.sm`
    border-bottom-left-radius: ${BORDER_RADIUS_0};
    border-bottom-right-radius: ${BORDER_RADIUS_0};
  `}

  ${media.gtSm`
    max-height: calc(100vh - 200px);
    width: 428px;
  `}
`

const ModalContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 0;
  padding: ${SPACING_LG};
  background-color: ${COLOR_LIGHTER};
`

export type ModalContentProps = PropsWithChildren

export const ModalContent = forwardRef<HTMLElement, ModalContentProps & HTMLProps<HTMLElement>>(
  ({ children, ...props }, propRef) => {
    const {
      context,
      descriptionId,
      floatingOverlayStyles,
      getFloatingProps,
      isMounted,
      labelId,
      modalContainerStyles,
      refs,
    } = useModalContext()
    const ref = useMergeRefs([refs.setFloating, propRef])

    if (!isMounted) {
      return null
    }

    return (
      <FloatingPortal>
        <FloatingOverlay lockScroll style={floatingOverlayStyles}>
          <FloatingFocusManager context={context}>
            <ModalContainer
              ref={ref}
              aria-describedby={descriptionId}
              aria-labelledby={labelId}
              aria-modal="true"
              size="md"
              {...getFloatingProps(props)}
              style={modalContainerStyles}
            >
              <ModalCloseContainer>
                <ModalClose />
              </ModalCloseContainer>
              <ModalContentWrapper>{children}</ModalContentWrapper>
            </ModalContainer>
          </FloatingFocusManager>
        </FloatingOverlay>
      </FloatingPortal>
    )
  },
)

export type ModalProps = PropsWithChildren<
  {
    children: [ReactElement<typeof ModalTrigger>, ReactElement<typeof ModalContent>] | ReactElement<typeof ModalContent>
  } & ModalOptions
>

export const Modal = ({ children, ...options }: ModalProps) => {
  const modal = useModal(options)

  return <ModalContext.Provider value={modal}>{children}</ModalContext.Provider>
}
