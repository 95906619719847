/**
 * THIS IS AN AUTOGENERATED FILE.
 * DO NOT EDIT THIS FILE DIRECTLY.
 */

import { FontWeightName } from '../types/font-weight'
import { cssvar, cssvarName, cssvarValue } from '../utils/cssvar'

export const DESIGN_TOKENS_FONT_WEIGHT_PREFIX = 'font-weight'

export const cssvarNameFontWeight = (fontWeight: FontWeightName) =>
  cssvarName(`${DESIGN_TOKENS_FONT_WEIGHT_PREFIX}-${fontWeight}`)

export const cssvarValueFontWeight = (fontWeight: FontWeightName) =>
  cssvarValue(`${DESIGN_TOKENS_FONT_WEIGHT_PREFIX}-${fontWeight}`)

export const cssvarFontWeight = (fontWeight: FontWeightName) =>
  cssvar(`${DESIGN_TOKENS_FONT_WEIGHT_PREFIX}-${fontWeight}`)
