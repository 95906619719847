import { BORDER_RADIUS_CIRCLE, BORDER_WIDTH_1, COLOR_BLACK, SPACING_3XS, SPACING_MD } from 'design-tokens'
import { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { Text, TextProps } from './Text'
import { shouldForwardProp } from './utils/shouldForwardProp'

type PillTextProps = TextProps

const PillText = styled(Text).withConfig({ displayName: 'Pill', shouldForwardProp })<PillTextProps>`
  padding: ${SPACING_3XS} ${SPACING_MD};
  border: ${BORDER_WIDTH_1} solid ${COLOR_BLACK};
  border-radius: ${BORDER_RADIUS_CIRCLE};
`

export type PillProps = PropsWithChildren

export const Pill = ({ children }: PillProps) => (
  <PillText colorName="darker" fontWeight="400" kind="caption">
    {children}
  </PillText>
)
