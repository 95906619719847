import { cssvarColor } from 'design-tokens'
import { assertNever } from 'functions'
import {
  Alarm,
  Apple,
  AppleImac2021,
  AppleMac,
  Archive,
  ArrowDown,
  ArrowDownCircle,
  ArrowLeft,
  ArrowLeftCircle,
  ArrowRight,
  ArrowRightCircle,
  ArrowUpCircle,
  Attachment,
  BadgeCheck,
  Bell,
  BellNotification,
  BinMinusIn,
  BookStack,
  Bookmark,
  BookmarkBook,
  Brain,
  Building,
  Calendar,
  ChatBubbleEmpty,
  ChatBubbleQuestion,
  ChatBubbleWarning,
  ChatLines,
  Check,
  CheckCircle,
  Community,
  Computer,
  Copy,
  CreditCard,
  CreditCardSlash,
  Donate,
  DoubleCheck,
  Download,
  Drag,
  Edit,
  EditPencil,
  Expand,
  Eye,
  EyeClosed,
  Facebook,
  FastRightCircle,
  Finder,
  Folder,
  Gift,
  Google,
  GoogleDocs,
  GraduationCap,
  Group,
  HeadsetHelp,
  Home,
  HomeHospital,
  HomeSimpleDoor,
  Hospital,
  Hourglass,
  Iconoir,
  Infinite,
  InfoCircle,
  Instagram,
  JournalPage,
  Language,
  Laptop,
  Lifebelt,
  LineSpace,
  Link,
  Linkedin,
  List,
  Lock,
  LockSquare,
  LogOut,
  LongArrowDownRight,
  Mail,
  MapPin,
  Medal,
  Medal1st,
  MediaImage,
  Menu,
  MenuScale,
  MessageText,
  Microphone,
  MicrophoneMute,
  Minus,
  MinusCircle,
  MoreHoriz,
  MoreVert,
  MultiWindow,
  MultiplePagesPlus,
  NavArrowDown,
  NavArrowLeft,
  NavArrowRight,
  NavArrowUp,
  OnTag,
  OneFingerSelectHandGesture,
  OpenInWindow,
  PageEdit,
  PageFlip,
  PagePlus,
  PageStar,
  PasteClipboard,
  Paypal,
  PcCheck,
  PeaceHand,
  PeopleTag,
  Percentage,
  PercentageCircle,
  PharmacyCrossTag,
  Phone,
  Plus,
  PrivacyPolicy,
  ProfileCircle,
  Prohibition,
  QuestionMark,
  ReceiveEuros,
  Reduce,
  RefreshDouble,
  Repeat,
  ReportColumns,
  Reports,
  Restart,
  Search,
  Send,
  SendDiagonal,
  SendMail,
  Server,
  Settings,
  ShareIos,
  SmartphoneDevice,
  SoundHigh,
  SoundOff,
  Sphere,
  Star,
  StatsUpSquare,
  ThreeStars,
  Timer,
  TimerOff,
  TransitionLeft,
  TransitionRight,
  Trash,
  Twitter,
  User,
  UserBadgeCheck,
  UserCircle,
  UserLove,
  UserScan,
  UserStar,
  UserXmark,
  VideoCamera,
  VideoCameraOff,
  ViewStructureUp,
  Wallet,
  WarningCircle,
  WarningCircleSolid,
  Xmark,
  XmarkCircle,
} from 'iconoir-react'
import { useMemo } from 'react'
import { IconName, IconProps, iconNames } from '../types'
import { AmericanExpress } from './AmericanExpress'
import { DeleteCircle } from './DeleteCircle'
import { Intercom } from './Intercom'
import { LogoApple } from './LogoApple'
import { LogoFacebook } from './LogoFacebook'
import { LogoGoogle } from './LogoGoogle'
import { Maestro } from './Maestro'
import { Mastercard } from './Mastercard'
import { PathCoaching } from './PathCoaching'
import { PathCouples } from './PathCouples'
import { PathMyself } from './PathMyself'
import { PathNutritionDca } from './PathNutritionDca'
import { PathNutritionWeightLoss } from './PathNutritionWeightLoss'
import { PathPsychiatry } from './PathPsychiatry'
import { PathSexology } from './PathSexology'
import { PostePay } from './PostePay'
import { PoweredByStripe } from './PoweredByStripe'
import { ReportsExcellent } from './ReportsExcellent'
import { ReportsGood } from './ReportsGood'
import { ReportsPoor } from './ReportsPoor'
import { Visa } from './Visa'
import { Whatsapp } from './Whatsapp'

export type MemoIconElementProps = {
  color: string
  fill: string
  height: IconProps['size']
  width: IconProps['size']
}

type MemoIconElement = (props: MemoIconElementProps) => JSX.Element | ReturnType<typeof Iconoir>

export const Icon = ({ colorName, fillColorName, name, size }: IconProps) => {
  const Component = useMemo((): MemoIconElement | null => {
    switch (name) {
      case 'alarm':
        return Alarm
      case 'american-express':
        return AmericanExpress
      case 'apple':
        return Apple
      case 'apple-imac-2021':
        return AppleImac2021
      case 'apple-mac':
        return AppleMac
      case 'archive':
        return Archive
      case 'arrow-down':
        return ArrowDown
      case 'arrow-down-circle':
        return ArrowDownCircle
      case 'arrow-left':
        return ArrowLeft
      case 'arrow-left-circle':
        return ArrowLeftCircle
      case 'arrow-right':
        return ArrowRight
      case 'arrow-right-circle':
        return ArrowRightCircle
      case 'arrow-up-circle':
        return ArrowUpCircle
      case 'attachment':
        return Attachment
      case 'badge-check':
        return BadgeCheck
      case 'bell':
        return Bell
      case 'bell-notification':
        return BellNotification
      case 'bin-minus-in':
        return BinMinusIn
      case 'book-stack':
        return BookStack
      case 'bookmark-book':
        return BookmarkBook
      case 'bookmark':
        return Bookmark
      case 'brain':
        return Brain
      case 'building':
        return Building
      case 'calendar':
        return Calendar
      case 'chat-bubble-empty':
        return ChatBubbleEmpty
      case 'chat-bubble-question':
        return ChatBubbleQuestion
      case 'chat-bubble-warning':
        return ChatBubbleWarning
      case 'chat-lines':
        return ChatLines
      case 'check':
        return Check
      case 'check-circle':
        return CheckCircle
      case 'community':
        return Community
      case 'computer':
        return Computer
      case 'copy':
        return Copy
      case 'credit-card':
        return CreditCard
      case 'credit-card-slash':
        return CreditCardSlash
      case 'delete-circle':
        return DeleteCircle
      case 'donate':
        return Donate
      case 'double-check':
        return DoubleCheck
      case 'download':
        return Download
      case 'drag':
        return Drag
      case 'fast-right-circle':
        return FastRightCircle
      case 'edit':
        return Edit
      case 'edit-pencil':
        return EditPencil
      case 'expand':
        return Expand
      case 'eye-closed':
        return EyeClosed
      case 'eye':
        return Eye
      case 'facebook':
        return Facebook
      case 'finder':
        return Finder
      case 'folder':
        return Folder
      case 'gift':
        return Gift
      case 'google':
        return Google
      case 'google-docs':
        return GoogleDocs
      case 'graduation-cap':
        return GraduationCap
      case 'group':
        return Group
      case 'headset-help':
        return HeadsetHelp
      case 'home':
        return Home
      case 'home-simple-door':
        return HomeSimpleDoor
      case 'hospital':
        return Hospital
      case 'home-hospital':
        return HomeHospital
      case 'hourglass':
        return Hourglass
      case 'infinite':
        return Infinite
      case 'info-circle':
        return InfoCircle
      case 'instagram':
        return Instagram
      case 'intercom':
        return Intercom
      case 'journal-page':
        return JournalPage
      case 'laptop':
        return Laptop
      case 'language':
        return Language
      case 'lifebelt':
        return Lifebelt
      case 'line-space':
        return LineSpace
      case 'link':
        return Link
      case 'linkedin':
        return Linkedin
      case 'list':
        return List
      case 'lock':
        return Lock
      case 'lock-square':
        return LockSquare
      case 'log-out':
        return LogOut
      case 'logo-apple':
        return LogoApple
      case 'logo-facebook':
        return LogoFacebook
      case 'logo-google':
        return LogoGoogle
      case 'long-arrow-down-right':
        return LongArrowDownRight
      case 'maestro':
        return Maestro
      case 'mail':
        return Mail
      case 'map-pin':
        return MapPin
      case 'mastercard':
        return Mastercard
      case 'medal':
        return Medal
      case 'medal-1st':
        return Medal1st
      case 'media-image':
        return MediaImage
      case 'menu':
        return Menu
      case 'menu-scale':
        return MenuScale
      case 'message-text':
        return MessageText
      case 'microphone':
        return Microphone
      case 'microphone-mute':
        return MicrophoneMute
      case 'minus':
        return Minus
      case 'minus-circle':
        return MinusCircle
      case 'more-horiz':
        return MoreHoriz
      case 'more-vert':
        return MoreVert
      case 'multi-window':
        return MultiWindow
      case 'multiple-pages-plus':
        return MultiplePagesPlus
      case 'nav-arrow-down':
        return NavArrowDown
      case 'nav-arrow-left':
        return NavArrowLeft
      case 'nav-arrow-right':
        return NavArrowRight
      case 'nav-arrow-up':
        return NavArrowUp
      case 'on-tag':
        return OnTag
      case 'one-finger-select-hand-gesture':
        return OneFingerSelectHandGesture
      case 'open-in-window':
        return OpenInWindow
      case 'page-edit':
        return PageEdit
      case 'page-flip':
        return PageFlip
      case 'page-plus':
        return PagePlus
      case 'page-star':
        return PageStar
      case 'paste-clipboard':
        return PasteClipboard
      case 'path-coaching':
        return PathCoaching
      case 'path-couples':
        return PathCouples
      case 'path-myself':
        return PathMyself
      case 'path-nutrition-dca':
        return PathNutritionDca
      case 'path-nutrition-weight-loss':
        return PathNutritionWeightLoss
      case 'path-psychiatry':
        return PathPsychiatry
      case 'path-sexology':
        return PathSexology
      case 'paypal':
        return Paypal
      case 'pc-check':
        return PcCheck
      case 'peace-hand':
        return PeaceHand
      case 'people-tag':
        return PeopleTag
      case 'percentage-circle':
        return PercentageCircle
      case 'percentage-round':
        return Percentage
      case 'pharmacy-cross-tag':
        return PharmacyCrossTag
      case 'phone':
        return Phone
      case 'plus':
        return Plus
      case 'poste-pay':
        return PostePay
      case 'powered-by-stripe':
        return PoweredByStripe
      case 'privacy-policy':
        return PrivacyPolicy
      case 'profile-circle':
        return ProfileCircle
      case 'prohibition':
        return Prohibition
      case 'question-mark':
        return QuestionMark
      case 'receive-euros':
        return ReceiveEuros
      case 'reduce':
        return Reduce
      case 'refresh-double':
        return RefreshDouble
      case 'repeat':
        return Repeat
      case 'report-columns':
        return ReportColumns
      case 'reports':
        return Reports
      case 'reports-excellent':
        return ReportsExcellent
      case 'reports-good':
        return ReportsGood
      case 'reports-poor':
        return ReportsPoor
      case 'restart':
        return Restart
      case 'search':
        return Search
      case 'send':
        return Send
      case 'send-diagonal':
        return SendDiagonal
      case 'send-mail':
        return SendMail
      case 'server':
        return Server
      case 'settings':
        return Settings
      case 'share-ios':
        return ShareIos
      case 'smartphone-device':
        return SmartphoneDevice
      case 'sound-high':
        return SoundHigh
      case 'sound-off':
        return SoundOff
      case 'sphere':
        return Sphere
      case 'star':
        return Star
      case 'stats-up-square':
        return StatsUpSquare
      case 'three-stars':
        return ThreeStars
      case 'timer':
        return Timer
      case 'timer-off':
        return TimerOff
      case 'transition-left':
        return TransitionLeft
      case 'transition-right':
        return TransitionRight
      case 'trash':
        return Trash
      case 'twitter':
        return Twitter
      case 'user':
        return User
      case 'user-badge-check':
        return UserBadgeCheck
      case 'user-circle':
        return UserCircle
      case 'user-love':
        return UserLove
      case 'user-scan':
        return UserScan
      case 'user-star':
        return UserStar
      case 'user-xmark':
        return UserXmark
      case 'video-camera':
        return VideoCamera
      case 'video-camera-off':
        return VideoCameraOff
      case 'view-structure-up':
        return ViewStructureUp
      case 'visa':
        return Visa
      case 'wallet':
        return Wallet
      case 'warning-circle':
        return WarningCircle
      case 'warning-circle-solid':
        return WarningCircleSolid
      case 'whatsapp':
        return Whatsapp
      case 'xmark':
        return Xmark
      case 'xmark-circle':
        return XmarkCircle
      default:
        return assertNever(name)
    }
  }, [name])

  if (!Component) {
    return null
  }

  return (
    <Component
      color={colorName ? cssvarColor(colorName) : 'currentColor'}
      data-test-id={`icon-${name}`}
      fill={fillColorName ? cssvarColor(fillColorName) : 'none'}
      height={size}
      width={size}
    />
  )
}

export const isIconName = (value: string): value is IconName => iconNames.includes(value as IconName)
