/**
 * THIS IS AN AUTOGENERATED FILE.
 * DO NOT EDIT THIS FILE DIRECTLY.
 */

import { DESIGN_TOKENS_PREFIX } from '../utils'

/** `0` */
export const BORDER_WIDTH_0 = `var(--${DESIGN_TOKENS_PREFIX}-border-width-0)` as const
export const BORDER_WIDTH_0_VALUE = '0' as const

/** `0.5px` */
export const BORDER_WIDTH_0_5 = `var(--${DESIGN_TOKENS_PREFIX}-border-width-0-5)` as const
export const BORDER_WIDTH_0_5_VALUE = '0.5px' as const

/** `1px` */
export const BORDER_WIDTH_1 = `var(--${DESIGN_TOKENS_PREFIX}-border-width-1)` as const
export const BORDER_WIDTH_1_VALUE = '1px' as const

/** `2px` */
export const BORDER_WIDTH_2 = `var(--${DESIGN_TOKENS_PREFIX}-border-width-2)` as const
export const BORDER_WIDTH_2_VALUE = '2px' as const

/** `4px` */
export const BORDER_WIDTH_4 = `var(--${DESIGN_TOKENS_PREFIX}-border-width-4)` as const
export const BORDER_WIDTH_4_VALUE = '4px' as const
