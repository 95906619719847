import { Locale } from 'date-fns'
import { formatWithOptions } from 'date-fns/fp'
import { enUS, it } from 'date-fns/locale'

export enum FormatDateEnum {
  ATOM = "yyyy-MM-dd'T'HH:mm:ssxxx",
  DATE_MONTH_NAME = 'dd MMMM',
  DATE_MONTH_NAME_YEAR = 'dd MMMM yyyy',
  DAY_MONTH_YEAR = 'dd/MM/yyyy',
  DAY_OF_MONTH = 'dd',
  DAY_OF_WEEK = 'i',
  HOURS = 'HH',
  HOURS_MINUTES = 'HH:mm',
  LONG_DAY_DATE_MONTH_NAME = 'eeee dd MMMM',
  MINUTES = 'mm',
  MONTH_NAME = 'MMMM',
  MONTH_NAME_YEAR = 'MMMM yyyy',
  MONTH_NAME_SHORT = 'MMM',
  MONTH_YEAR = 'MM/yyyy',
  /** @deprecated Use `FormatDateEnum.LONG_DAY_DATE_MONTH_NAME` */
  SHORT_DAY_DATE_MONTH_NAME = 'eee, dd MMMM',
  WEEK_DAY = 'EEEE',
  WEEK_DAY_SHORT = 'EEE',
  WEEK_DAY_SHORT_DAY = 'EEE d',
  YEAR = 'yyyy',
  YEAR_SHORT = 'yy',
  YEAR_MONTH = 'yyyy-MM',
  YEAR_MONTH_DAY = 'yyyy-MM-dd',
  YEAR_MONTH_DAY_NO_HYPHEN = 'yyyyMMdd',
}

export type FormatDateLanguage = 'it' | 'en'

export const fromFormatDateLanguageToDateFnsLocale: Record<FormatDateLanguage, Locale> = {
  en: enUS,
  it,
} as const

export const format = (value: FormatDateEnum, locale?: FormatDateLanguage) =>
  formatWithOptions({ locale: locale && fromFormatDateLanguageToDateFnsLocale[locale], weekStartsOn: 1 }, value)
