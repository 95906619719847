import { Flex, FlexProps, OverflowAuto } from 'cdk'
import { snakeCase } from 'change-case-all'
import {
  BORDER_RADIUS_CIRCLE,
  COLOR_PRIMARY,
  COLOR_WHITE,
  SPACING_3XS,
  SPACING_MD,
  SPACING_XS,
  TIME_150,
} from 'design-tokens'
import {
  Children,
  KeyboardEvent,
  PropsWithChildren,
  ReactElement,
  isValidElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'
import { Text } from './Text'

const TabList = styled(OverflowAuto).withConfig({ displayName: 'TabList' })`
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

const TabListItem = styled(Flex).withConfig({ displayName: 'TabListItem' })`
  background-color: ${COLOR_WHITE};
  color: ${COLOR_PRIMARY};
  cursor: pointer;
  transition:
    color ${TIME_150} ease,
    background-color ${TIME_150} ease-in-out;

  &[aria-selected='true'],
  &:focus-visible {
    background-color: ${COLOR_PRIMARY};
    color: ${COLOR_WHITE};
  }
`

const TabPanel = styled(Flex).withConfig({ displayName: 'TabPanel' })`
  &[aria-hidden='true'] {
    display: none;
  }
`

type TabProps = FlexProps & {
  title: string
}

export const Tab = styled(Flex).withConfig({ displayName: 'TabPanel' })<TabProps>`
  /* stylelint-disable-next-line no-empty-source */
`

export type TabsProps = PropsWithChildren<{
  initialSelectedTabIndex?: number
}>

export const Tabs = ({ children, initialSelectedTabIndex = 0 }: TabsProps) => {
  const tabRefs = useRef<HTMLLIElement[]>([])

  const tabs = useMemo(
    () =>
      Children.toArray(children)
        .filter((child): child is ReactElement<TabProps> => isValidElement<TabProps>(child))
        .map(({ props: { children, title } }) => ({
          children,
          tabName: snakeCase(title),
          title,
        })),
    [children],
  )

  const [selectedTab, setSelectedTab] = useState(() =>
    Math.max(Math.min(initialSelectedTabIndex, Math.max(tabs.length - 1, 0)), 0),
  )

  const handleOnClick = useCallback((index: number) => {
    setSelectedTab(index)
  }, [])

  useEffect(() => {
    tabRefs.current[initialSelectedTabIndex]?.scrollIntoView()
  }, [initialSelectedTabIndex])

  const handleOnKeyDown = useCallback(
    (event: KeyboardEvent) => {
      let newSelectedTab = selectedTab

      if (event.key === 'ArrowRight' && selectedTab < tabs.length - 1) {
        newSelectedTab = selectedTab + 1
      }

      if (event.key === 'ArrowLeft' && selectedTab > 0) {
        newSelectedTab = selectedTab - 1
      }

      setSelectedTab(newSelectedTab)
      tabRefs.current[newSelectedTab]?.focus()
    },
    [selectedTab, tabs],
  )

  return (
    <>
      <TabList $direction="row" $gap={SPACING_3XS} $grow={0} $p={1} $shrink={0} as="ul" role="tablist">
        {tabs.map(({ tabName, title }, index) => (
          <TabListItem
            key={tabName}
            ref={(element) => {
              if (element) {
                tabRefs.current[index] = element
              }
            }}
            $borderRadius={BORDER_RADIUS_CIRCLE}
            $px={SPACING_MD}
            $py={SPACING_XS}
            aria-controls={`tab-${tabName}`}
            aria-selected={selectedTab === index}
            as="li"
            data-test-id={`tab-${tabName}`}
            id={`tab-${tabName}`}
            onClick={() => handleOnClick(index)}
            onKeyDown={handleOnKeyDown}
            role="tab"
            tabIndex={selectedTab === index ? 0 : -1}
          >
            <Text fontWeight="600" kind="paragraph">
              {title}
            </Text>
          </TabListItem>
        ))}
      </TabList>
      {tabs.map(({ children, tabName }, index) => (
        <TabPanel
          key={tabName}
          $grow={1}
          $pt={SPACING_MD}
          $shrink={1}
          aria-hidden={selectedTab !== index}
          aria-labelledby={`tab-${tabName}`}
          id={`tabpanel-${tabName}`}
          role="tabpanel"
        >
          {children}
        </TabPanel>
      ))}
    </>
  )
}
