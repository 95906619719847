import { BORDER_RADIUS_CIRCLE, COLOR_VIOLET_40, TIME_150 } from 'design-tokens'
import { memo, PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react'
import { acronym } from 'strings'
import styled, { css } from 'styled-components'
import { Text, TextProps } from './Text'
import { shouldForwardProp } from './utils/shouldForwardProp'

const sizes = ['xs', 'sm', 'md', 'lg', 'xl', '2xl'] as const

type Size = (typeof sizes)[number]

export const avatarSizesDimensions: Record<
  Size,
  {
    size: number
    fontKind: TextProps['kind']
    fontWeight: TextProps['fontWeight']
  }
> = {
  xs: { size: 24, fontKind: 'footnote', fontWeight: '400' },
  sm: { size: 40, fontKind: 'caption', fontWeight: '400' },
  md: { size: 48, fontKind: 'paragraph', fontWeight: '400' },
  lg: { size: 64, fontKind: 'h2', fontWeight: '400' },
  xl: { size: 80, fontKind: 'h1', fontWeight: '400' },
  '2xl': { size: 96, fontKind: 'h1', fontWeight: '400' },
}

type AvatarContainerProps = PropsWithChildren<{
  size: Size
}>

const AvatarContainer = styled.div.withConfig({
  displayName: 'Avatar',
  shouldForwardProp,
})<AvatarContainerProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ size }: AvatarContainerProps) => css`
    width: ${avatarSizesDimensions[size].size}px;
    height: ${avatarSizesDimensions[size].size}px;
  `}
  overflow: hidden;
  border-radius: ${BORDER_RADIUS_CIRCLE};
  background-color: ${COLOR_VIOLET_40};
  text-align: center;
`

type AvatarImageProps = {
  isImageLoaded: boolean
}

const AvatarImage = styled.img.withConfig({ displayName: 'Image', shouldForwardProp })<AvatarImageProps>`
  position: absolute;
  inset: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  opacity: ${({ isImageLoaded }) => (isImageLoaded ? 1 : 0)};
  transition: opacity ${TIME_150} ease;
`

export type AvatarProps = {
  image?: string
  name?: string
  size: Size
}

export const Avatar = memo(({ image, name, size }: AvatarProps) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false)
  const [hasImageError, setHasImageError] = useState(false)

  const imageRef = useRef<HTMLImageElement>(null)
  const uuid = `avatar_${name}`

  const handleOnLoad = useCallback(() => {
    setIsImageLoaded(true)
  }, [])

  const handleOnError = useCallback(() => {
    setHasImageError(true)
  }, [])

  useEffect(() => {
    if (isImageLoaded) {
      setIsImageLoaded(true)

      return
    }

    if (!imageRef.current?.complete) {
      return
    }
  }, [isImageLoaded])

  return (
    <AvatarContainer size={size}>
      {name && (
        <Text
          as="abbr"
          colorName="white"
          fontWeight={avatarSizesDimensions[size].fontWeight}
          id={uuid}
          kind={avatarSizesDimensions[size].fontKind}
          textDecoration="none"
          title={name}
        >
          {acronym(name)}
        </Text>
      )}
      {image && !hasImageError && (
        <AvatarImage
          ref={imageRef}
          alt={name}
          aria-labelledby={name && uuid}
          isImageLoaded={isImageLoaded}
          loading="lazy"
          onError={handleOnError}
          onLoad={handleOnLoad}
          role="img"
          src={image}
          title={name}
        />
      )}
    </AvatarContainer>
  )
})

Avatar.displayName = 'Avatar'
