import {
  BORDER_RADIUS_2XS_VALUE,
  BORDER_WIDTH_1,
  COLOR_DARKER,
  COLOR_GREY_20,
  COLOR_GREY_40,
  COLOR_LIGHTER,
  COLOR_VIOLET_50,
  COLOR_WHITE,
  TIME_150,
} from 'design-tokens'
import { forwardRef, InputHTMLAttributes } from 'react'
import styled from 'styled-components'

const Container = styled.div.withConfig({ displayName: 'Checkbox' })`
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: ${BORDER_RADIUS_2XS_VALUE};
  color: ${COLOR_VIOLET_50};

  .srns-dark & {
    color: ${COLOR_DARKER};
  }

  svg {
    position: absolute;
    inset: 0;
    vertical-align: top;
    pointer-events: none;

    path {
      fill: none;
      transition: stroke-dashoffset ${TIME_150} cubic-bezier(0.215, 0.61, 0.355, 1);
      stroke-width: 3;
      stroke-dasharray: 50;
      stroke-dashoffset: 50;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
  }

  &:has(:disabled) {
    color: ${COLOR_GREY_40};
  }

  &:has(:checked) {
    color: ${COLOR_WHITE};

    path {
      stroke-dashoffset: 35;
    }
  }
`

const Input = styled.input.withConfig({ displayName: 'CheckboxInput' })`
  position: absolute;
  inset: 0;
  margin: 0;
  border: ${BORDER_WIDTH_1} solid ${COLOR_VIOLET_50};
  border-radius: ${BORDER_RADIUS_2XS_VALUE};
  background-color: ${COLOR_LIGHTER};
  cursor: pointer;
  transition: background-color ${TIME_150} ease;
  appearance: none;

  &:disabled {
    border-color: ${COLOR_GREY_40};
    background-color: ${COLOR_GREY_20};
    cursor: not-allowed;
  }

  &:checked {
    background-color: ${COLOR_VIOLET_50};

    &:disabled {
      background-color: ${COLOR_GREY_40};
    }
  }
`

const Sign = () => (
  <svg aria-hidden="true" focusable="false" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="m7 12.5 3 3 7-7" stroke="currentColor" />
  </svg>
)

export type CheckboxProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> & {
  /** Missing invalid style @see https://linear.app/serenis/issue/PRD-6193/implementare-stile-invalid-per-checkbox */
  isInvalid?: boolean
  /** Use `name` for `aria-labelledby` @see https://www.w3.org/WAI/tutorials/forms/instructions/ */
  name?: string
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => (
  <Container>
    <Input
      ref={ref}
      aria-checked={props.checked}
      aria-disabled={props.disabled}
      aria-invalid={props.isInvalid}
      type="checkbox"
      {...props}
    />
    <Sign />
  </Container>
))
