/**
 * THIS IS AN AUTOGENERATED FILE.
 * DO NOT EDIT THIS FILE DIRECTLY.
 */

import { DESIGN_TOKENS_PREFIX, cssvarValue } from '../utils'

/** In `light` theme is `#5039D8` */
export const COLOR_PRIMARY = `var(--${DESIGN_TOKENS_PREFIX}-color-primary)` as const
export const COLOR_PRIMARY_VALUE = cssvarValue('color-primary')

/** In `light` theme is `#ffffff` */
export const COLOR_LIGHTER = `var(--${DESIGN_TOKENS_PREFIX}-color-lighter)` as const
export const COLOR_LIGHTER_VALUE = cssvarValue('color-lighter')

/** In `light` theme is `#141323` */
export const COLOR_DARKER = `var(--${DESIGN_TOKENS_PREFIX}-color-darker)` as const
export const COLOR_DARKER_VALUE = cssvarValue('color-darker')

/** In `light` theme is `#12979E` */
export const COLOR_SUCCESS = `var(--${DESIGN_TOKENS_PREFIX}-color-success)` as const
export const COLOR_SUCCESS_VALUE = cssvarValue('color-success')

/** In `light` theme is `#0A84FF` */
export const COLOR_INFO = `var(--${DESIGN_TOKENS_PREFIX}-color-info)` as const
export const COLOR_INFO_VALUE = cssvarValue('color-info')

/** In `light` theme is `#E1AF0F` */
export const COLOR_WARNING = `var(--${DESIGN_TOKENS_PREFIX}-color-warning)` as const
export const COLOR_WARNING_VALUE = cssvarValue('color-warning')

/** In `light` theme is `#FF545B` */
export const COLOR_ERROR = `var(--${DESIGN_TOKENS_PREFIX}-color-error)` as const
export const COLOR_ERROR_VALUE = cssvarValue('color-error')

/** `#FFFFFF` */
export const COLOR_GREY_10 = `var(--${DESIGN_TOKENS_PREFIX}-color-grey-10)` as const
export const COLOR_GREY_10_VALUE = '#FFFFFF' as const

/** `#82828F` @deprecated Use new token `COLOR_GREY_80` */
export const COLOR_GREY_11 = `var(--${DESIGN_TOKENS_PREFIX}-color-grey-11)` as const
/** @deprecated Use new token `COLOR_GREY_80_VALUE` */
export const COLOR_GREY_11_VALUE = '#82828F' as const

/** `#F6F7FD` */
export const COLOR_GREY_20 = `var(--${DESIGN_TOKENS_PREFIX}-color-grey-20)` as const
export const COLOR_GREY_20_VALUE = '#F6F7FD' as const

/** `#ECEDF9` */
export const COLOR_GREY_30 = `var(--${DESIGN_TOKENS_PREFIX}-color-grey-30)` as const
export const COLOR_GREY_30_VALUE = '#ECEDF9' as const

/** `#D7D7EA` */
export const COLOR_GREY_40 = `var(--${DESIGN_TOKENS_PREFIX}-color-grey-40)` as const
export const COLOR_GREY_40_VALUE = '#D7D7EA' as const

/** `#A7A7D2` */
export const COLOR_GREY_50 = `var(--${DESIGN_TOKENS_PREFIX}-color-grey-50)` as const
export const COLOR_GREY_50_VALUE = '#A7A7D2' as const

/** `#8686C1` */
export const COLOR_GREY_60 = `var(--${DESIGN_TOKENS_PREFIX}-color-grey-60)` as const
export const COLOR_GREY_60_VALUE = '#8686C1' as const

/** `#7A7AAE` */
export const COLOR_GREY_70 = `var(--${DESIGN_TOKENS_PREFIX}-color-grey-70)` as const
export const COLOR_GREY_70_VALUE = '#7A7AAE' as const

/** `#5D5D98` */
export const COLOR_GREY_80 = `var(--${DESIGN_TOKENS_PREFIX}-color-grey-80)` as const
export const COLOR_GREY_80_VALUE = '#5D5D98' as const

/** `#3E3E79` */
export const COLOR_GREY_90 = `var(--${DESIGN_TOKENS_PREFIX}-color-grey-90)` as const
export const COLOR_GREY_90_VALUE = '#3E3E79' as const

/** `#2D2D58` */
export const COLOR_GREY_100 = `var(--${DESIGN_TOKENS_PREFIX}-color-grey-100)` as const
export const COLOR_GREY_100_VALUE = '#2D2D58' as const

/** `#1C1C36` */
export const COLOR_GREY_110 = `var(--${DESIGN_TOKENS_PREFIX}-color-grey-110)` as const
export const COLOR_GREY_110_VALUE = '#1C1C36' as const

/** `#0B0B1E` */
export const COLOR_GREY_120 = `var(--${DESIGN_TOKENS_PREFIX}-color-grey-120)` as const
export const COLOR_GREY_120_VALUE = '#0B0B1E' as const

/** `#B6B6C1` @deprecated Use new token `COLOR_GREY_60` */
export const COLOR_GREY_08 = `var(--${DESIGN_TOKENS_PREFIX}-color-grey-08)` as const
/** @deprecated Use new token `COLOR_GREY_60_VALUE` */
export const COLOR_GREY_08_VALUE = '#B6B6C1' as const

/** `#C8C8D2` @deprecated Use new token `COLOR_GREY_50` */
export const COLOR_GREY_06 = `var(--${DESIGN_TOKENS_PREFIX}-color-grey-06)` as const
/** @deprecated Use new token `COLOR_GREY_50_VALUE` */
export const COLOR_GREY_06_VALUE = '#C8C8D2' as const

/** `#E0E0E7` @deprecated Use new token `COLOR_GREY_40` */
export const COLOR_GREY_04 = `var(--${DESIGN_TOKENS_PREFIX}-color-grey-04)` as const
/** @deprecated Use new token `COLOR_GREY_40_VALUE` */
export const COLOR_GREY_04_VALUE = '#E0E0E7' as const

/** `#F0EFF4` @deprecated Use new token `COLOR_GREY_30` */
export const COLOR_GREY_02 = `var(--${DESIGN_TOKENS_PREFIX}-color-grey-02)` as const
/** @deprecated Use new token `COLOR_GREY_30_VALUE` */
export const COLOR_GREY_02_VALUE = '#F0EFF4' as const

/** `transparent` */
export const COLOR_TRANSPARENT = `var(--${DESIGN_TOKENS_PREFIX}-color-transparent)` as const
export const COLOR_TRANSPARENT_VALUE = 'transparent' as const

/** `#141323` @deprecated Use new token `COLOR_DARKER` */
export const COLOR_BLACK = `var(--${DESIGN_TOKENS_PREFIX}-color-black)` as const
/** @deprecated Use new token `COLOR_DARKER_VALUE` */
export const COLOR_BLACK_VALUE = '#141323' as const

/** `#ffffff` @deprecated Use new token `COLOR_LIGHTER` */
export const COLOR_WHITE = `var(--${DESIGN_TOKENS_PREFIX}-color-white)` as const
/** @deprecated Use new token `COLOR_LIGHTER_VALUE` */
export const COLOR_WHITE_VALUE = '#ffffff' as const

/** `#CAFACB` */
export const COLOR_MINTTEA_20 = `var(--${DESIGN_TOKENS_PREFIX}-color-minttea-20)` as const
export const COLOR_MINTTEA_20_VALUE = '#CAFACB' as const

/** `#179A92` */
export const COLOR_MINTTEA_40 = `var(--${DESIGN_TOKENS_PREFIX}-color-minttea-40)` as const
export const COLOR_MINTTEA_40_VALUE = '#179A92' as const

/** `#003B2E` */
export const COLOR_MINTTEA_60 = `var(--${DESIGN_TOKENS_PREFIX}-color-minttea-60)` as const
export const COLOR_MINTTEA_60_VALUE = '#003B2E' as const

/** `#061A1D` */
export const COLOR_MINTTEA_80 = `var(--${DESIGN_TOKENS_PREFIX}-color-minttea-80)` as const
export const COLOR_MINTTEA_80_VALUE = '#061A1D' as const

/** In `light` theme is `#ffffff` */
export const COLOR_NEUTRAL_10 = `var(--${DESIGN_TOKENS_PREFIX}-color-neutral-10)` as const
export const COLOR_NEUTRAL_10_VALUE = cssvarValue('color-neutral-10')

/** In `light` theme is `#F0EFF4` */
export const COLOR_NEUTRAL_20 = `var(--${DESIGN_TOKENS_PREFIX}-color-neutral-20)` as const
export const COLOR_NEUTRAL_20_VALUE = cssvarValue('color-neutral-20')

/** In `light` theme is `#F0EFF4` */
export const COLOR_NEUTRAL_30 = `var(--${DESIGN_TOKENS_PREFIX}-color-neutral-30)` as const
export const COLOR_NEUTRAL_30_VALUE = cssvarValue('color-neutral-30')

/** In `light` theme is `#E0E0E7` */
export const COLOR_NEUTRAL_40 = `var(--${DESIGN_TOKENS_PREFIX}-color-neutral-40)` as const
export const COLOR_NEUTRAL_40_VALUE = cssvarValue('color-neutral-40')

/** In `light` theme is `#C8C8D2` */
export const COLOR_NEUTRAL_50 = `var(--${DESIGN_TOKENS_PREFIX}-color-neutral-50)` as const
export const COLOR_NEUTRAL_50_VALUE = cssvarValue('color-neutral-50')

/** In `light` theme is `#B6B6C1` */
export const COLOR_NEUTRAL_60 = `var(--${DESIGN_TOKENS_PREFIX}-color-neutral-60)` as const
export const COLOR_NEUTRAL_60_VALUE = cssvarValue('color-neutral-60')

/** In `light` theme is `#B6B6C1` */
export const COLOR_NEUTRAL_70 = `var(--${DESIGN_TOKENS_PREFIX}-color-neutral-70)` as const
export const COLOR_NEUTRAL_70_VALUE = cssvarValue('color-neutral-70')

/** In `light` theme is `#82828F` */
export const COLOR_NEUTRAL_80 = `var(--${DESIGN_TOKENS_PREFIX}-color-neutral-80)` as const
export const COLOR_NEUTRAL_80_VALUE = cssvarValue('color-neutral-80')

/** In `light` theme is `#82828F` */
export const COLOR_NEUTRAL_90 = `var(--${DESIGN_TOKENS_PREFIX}-color-neutral-90)` as const
export const COLOR_NEUTRAL_90_VALUE = cssvarValue('color-neutral-90')

/** In `light` theme is `#82828F` */
export const COLOR_NEUTRAL_100 = `var(--${DESIGN_TOKENS_PREFIX}-color-neutral-100)` as const
export const COLOR_NEUTRAL_100_VALUE = cssvarValue('color-neutral-100')

/** In `light` theme is `#141323` */
export const COLOR_NEUTRAL_110 = `var(--${DESIGN_TOKENS_PREFIX}-color-neutral-110)` as const
export const COLOR_NEUTRAL_110_VALUE = cssvarValue('color-neutral-110')

/** In `light` theme is `#141323` */
export const COLOR_NEUTRAL_120 = `var(--${DESIGN_TOKENS_PREFIX}-color-neutral-120)` as const
export const COLOR_NEUTRAL_120_VALUE = cssvarValue('color-neutral-120')

/** In `light` theme is `#F2F2FF` */
export const COLOR_VIOLET_10 = `var(--${DESIGN_TOKENS_PREFIX}-color-violet-10)` as const
export const COLOR_VIOLET_10_VALUE = cssvarValue('color-violet-10')

/** In `light` theme is `#F2F2FF` */
export const COLOR_VIOLET_20 = `var(--${DESIGN_TOKENS_PREFIX}-color-violet-20)` as const
export const COLOR_VIOLET_20_VALUE = cssvarValue('color-violet-20')

/** In `light` theme is `#DFDAF6` */
export const COLOR_VIOLET_30 = `var(--${DESIGN_TOKENS_PREFIX}-color-violet-30)` as const
export const COLOR_VIOLET_30_VALUE = cssvarValue('color-violet-30')

/** In `light` theme is `#B5B2EA` */
export const COLOR_VIOLET_40 = `var(--${DESIGN_TOKENS_PREFIX}-color-violet-40)` as const
export const COLOR_VIOLET_40_VALUE = cssvarValue('color-violet-40')

/** In `light` theme is `#5039D8` */
export const COLOR_VIOLET_50 = `var(--${DESIGN_TOKENS_PREFIX}-color-violet-50)` as const
export const COLOR_VIOLET_50_VALUE = cssvarValue('color-violet-50')

/** In `light` theme is `#5039D8` */
export const COLOR_VIOLET_60 = `var(--${DESIGN_TOKENS_PREFIX}-color-violet-60)` as const
export const COLOR_VIOLET_60_VALUE = cssvarValue('color-violet-60')

/** In `light` theme is `#5039D8` */
export const COLOR_VIOLET_70 = `var(--${DESIGN_TOKENS_PREFIX}-color-violet-70)` as const
export const COLOR_VIOLET_70_VALUE = cssvarValue('color-violet-70')

/** In `light` theme is `#382897` */
export const COLOR_VIOLET_80 = `var(--${DESIGN_TOKENS_PREFIX}-color-violet-80)` as const
export const COLOR_VIOLET_80_VALUE = cssvarValue('color-violet-80')

/** `#0D6A6F` @deprecated Use new token `COLOR_GREEN_80` */
export const COLOR_GREEN_10 = `var(--${DESIGN_TOKENS_PREFIX}-color-green-10)` as const
/** @deprecated Use new token `COLOR_GREEN_80_VALUE` */
export const COLOR_GREEN_10_VALUE = '#0D6A6F' as const

/** In `light` theme is `#C3E3DF` */
export const COLOR_GREEN_20 = `var(--${DESIGN_TOKENS_PREFIX}-color-green-20)` as const
export const COLOR_GREEN_20_VALUE = cssvarValue('color-green-20')

/** In `light` theme is `#81CCCA` */
export const COLOR_GREEN_40 = `var(--${DESIGN_TOKENS_PREFIX}-color-green-40)` as const
export const COLOR_GREEN_40_VALUE = cssvarValue('color-green-40')

/** In `light` theme is `#12979E` */
export const COLOR_GREEN_60 = `var(--${DESIGN_TOKENS_PREFIX}-color-green-60)` as const
export const COLOR_GREEN_60_VALUE = cssvarValue('color-green-60')

/** In `light` theme is `#0D6A6F` */
export const COLOR_GREEN_80 = `var(--${DESIGN_TOKENS_PREFIX}-color-green-80)` as const
export const COLOR_GREEN_80_VALUE = cssvarValue('color-green-80')

/** `#12979E` @deprecated Use new token `COLOR_GREEN_60` */
export const COLOR_GREEN_08 = `var(--${DESIGN_TOKENS_PREFIX}-color-green-08)` as const
/** @deprecated Use new token `COLOR_GREEN_60_VALUE` */
export const COLOR_GREEN_08_VALUE = '#12979E' as const

/** `#81CCCA` @deprecated Use new token `COLOR_GREEN_40` */
export const COLOR_GREEN_06 = `var(--${DESIGN_TOKENS_PREFIX}-color-green-06)` as const
/** @deprecated Use new token `COLOR_GREEN_40_VALUE` */
export const COLOR_GREEN_06_VALUE = '#81CCCA' as const

/** `#C3E3DF` @deprecated Use new token `COLOR_GREEN_20` */
export const COLOR_GREEN_04 = `var(--${DESIGN_TOKENS_PREFIX}-color-green-04)` as const
/** @deprecated Use new token `COLOR_GREEN_20_VALUE` */
export const COLOR_GREEN_04_VALUE = '#C3E3DF' as const

/** `#EBF4F4` @deprecated Use new token `COLOR_GREEN_20` */
export const COLOR_GREEN_02 = `var(--${DESIGN_TOKENS_PREFIX}-color-green-02)` as const
/** @deprecated Use new token `COLOR_GREEN_20_VALUE` */
export const COLOR_GREEN_02_VALUE = '#EBF4F4' as const

/** `#B48C0C` @deprecated Use new token `COLOR_YELLOW_80` */
export const COLOR_YELLOW_10 = `var(--${DESIGN_TOKENS_PREFIX}-color-yellow-10)` as const
/** @deprecated Use new token `COLOR_YELLOW_80_VALUE` */
export const COLOR_YELLOW_10_VALUE = '#B48C0C' as const

/** In `light` theme is `#F7E7B7` */
export const COLOR_YELLOW_20 = `var(--${DESIGN_TOKENS_PREFIX}-color-yellow-20)` as const
export const COLOR_YELLOW_20_VALUE = cssvarValue('color-yellow-20')

/** In `light` theme is `#ECCE6F` */
export const COLOR_YELLOW_40 = `var(--${DESIGN_TOKENS_PREFIX}-color-yellow-40)` as const
export const COLOR_YELLOW_40_VALUE = cssvarValue('color-yellow-40')

/** In `light` theme is `#E1AF0F` */
export const COLOR_YELLOW_60 = `var(--${DESIGN_TOKENS_PREFIX}-color-yellow-60)` as const
export const COLOR_YELLOW_60_VALUE = cssvarValue('color-yellow-60')

/** In `light` theme is `#B48C0C` */
export const COLOR_YELLOW_80 = `var(--${DESIGN_TOKENS_PREFIX}-color-yellow-80)` as const
export const COLOR_YELLOW_80_VALUE = cssvarValue('color-yellow-80')

/** `#E1AF0F` @deprecated Use new token `COLOR_YELLOW_60` */
export const COLOR_YELLOW_08 = `var(--${DESIGN_TOKENS_PREFIX}-color-yellow-08)` as const
/** @deprecated Use new token `COLOR_YELLOW_60_VALUE` */
export const COLOR_YELLOW_08_VALUE = '#E1AF0F' as const

/** `#ECCE6F` @deprecated Use new token `COLOR_YELLOW_40` */
export const COLOR_YELLOW_06 = `var(--${DESIGN_TOKENS_PREFIX}-color-yellow-06)` as const
/** @deprecated Use new token `COLOR_YELLOW_40_VALUE` */
export const COLOR_YELLOW_06_VALUE = '#ECCE6F' as const

/** `#F7E7B7` @deprecated Use new token `COLOR_YELLOW_20` */
export const COLOR_YELLOW_04 = `var(--${DESIGN_TOKENS_PREFIX}-color-yellow-04)` as const
/** @deprecated Use new token `COLOR_YELLOW_20_VALUE` */
export const COLOR_YELLOW_04_VALUE = '#F7E7B7' as const

/** `#FEFCEF` @deprecated Use new token `COLOR_YELLOW_20` */
export const COLOR_YELLOW_02 = `var(--${DESIGN_TOKENS_PREFIX}-color-yellow-02)` as const
/** @deprecated Use new token `COLOR_YELLOW_20_VALUE` */
export const COLOR_YELLOW_02_VALUE = '#FEFCEF' as const

/** In `light` theme is `#DBEDFF` */
export const COLOR_BLUE_20 = `var(--${DESIGN_TOKENS_PREFIX}-color-blue-20)` as const
export const COLOR_BLUE_20_VALUE = cssvarValue('color-blue-20')

/** In `light` theme is `#70C6FF` */
export const COLOR_BLUE_40 = `var(--${DESIGN_TOKENS_PREFIX}-color-blue-40)` as const
export const COLOR_BLUE_40_VALUE = cssvarValue('color-blue-40')

/** In `light` theme is `#0A84FF` */
export const COLOR_BLUE_60 = `var(--${DESIGN_TOKENS_PREFIX}-color-blue-60)` as const
export const COLOR_BLUE_60_VALUE = cssvarValue('color-blue-60')

/** In `light` theme is `#0A4299` */
export const COLOR_BLUE_80 = `var(--${DESIGN_TOKENS_PREFIX}-color-blue-80)` as const
export const COLOR_BLUE_80_VALUE = cssvarValue('color-blue-80')

/** In `light` theme is `#FFDADE` */
export const COLOR_RED_20 = `var(--${DESIGN_TOKENS_PREFIX}-color-red-20)` as const
export const COLOR_RED_20_VALUE = cssvarValue('color-red-20')

/** In `light` theme is `#FFAEB4` */
export const COLOR_RED_40 = `var(--${DESIGN_TOKENS_PREFIX}-color-red-40)` as const
export const COLOR_RED_40_VALUE = cssvarValue('color-red-40')

/** In `light` theme is `#FF545B` */
export const COLOR_RED_60 = `var(--${DESIGN_TOKENS_PREFIX}-color-red-60)` as const
export const COLOR_RED_60_VALUE = cssvarValue('color-red-60')

/** In `light` theme is `#B33B40` */
export const COLOR_RED_80 = `var(--${DESIGN_TOKENS_PREFIX}-color-red-80)` as const
export const COLOR_RED_80_VALUE = cssvarValue('color-red-80')

/** `#382897` @deprecated Use new token `COLOR_VIOLET_80` */
export const COLOR_PURPLE_10 = `var(--${DESIGN_TOKENS_PREFIX}-color-purple-10)` as const
/** @deprecated Use new token `COLOR_VIOLET_80_VALUE` */
export const COLOR_PURPLE_10_VALUE = '#382897' as const

/** `#5039D8` @deprecated Use new token `COLOR_VIOLET_50` */
export const COLOR_PURPLE_08 = `var(--${DESIGN_TOKENS_PREFIX}-color-purple-08)` as const
/** @deprecated Use new token `COLOR_VIOLET_50_VALUE` */
export const COLOR_PURPLE_08_VALUE = '#5039D8' as const

/** `#B5B2EA` @deprecated Use new token `COLOR_VIOLET_40` */
export const COLOR_PURPLE_06 = `var(--${DESIGN_TOKENS_PREFIX}-color-purple-06)` as const
/** @deprecated Use new token `COLOR_VIOLET_40_VALUE` */
export const COLOR_PURPLE_06_VALUE = '#B5B2EA' as const

/** `#DFDAF6` @deprecated Use new token `COLOR_VIOLET_30` */
export const COLOR_PURPLE_04 = `var(--${DESIGN_TOKENS_PREFIX}-color-purple-04)` as const
/** @deprecated Use new token `COLOR_VIOLET_30_VALUE` */
export const COLOR_PURPLE_04_VALUE = '#DFDAF6' as const

/** `#F2F2FF` @deprecated Use new token `COLOR_VIOLET_10` */
export const COLOR_PURPLE_02 = `var(--${DESIGN_TOKENS_PREFIX}-color-purple-02)` as const
/** @deprecated Use new token `COLOR_VIOLET_10_VALUE` */
export const COLOR_PURPLE_02_VALUE = '#F2F2FF' as const

/** `#B33B40` @deprecated Use new token `COLOR_RED_80` */
export const COLOR_CORAL_10 = `var(--${DESIGN_TOKENS_PREFIX}-color-coral-10)` as const
/** @deprecated Use new token `COLOR_RED_80_VALUE` */
export const COLOR_CORAL_10_VALUE = '#B33B40' as const

/** `#FF545B` @deprecated Use new token `COLOR_RED_60` */
export const COLOR_CORAL_08 = `var(--${DESIGN_TOKENS_PREFIX}-color-coral-08)` as const
/** @deprecated Use new token `COLOR_RED_60_VALUE` */
export const COLOR_CORAL_08_VALUE = '#FF545B' as const

/** `#FFAEB4` @deprecated Use new token `COLOR_RED_40` */
export const COLOR_CORAL_06 = `var(--${DESIGN_TOKENS_PREFIX}-color-coral-06)` as const
/** @deprecated Use new token `COLOR_RED_40_VALUE` */
export const COLOR_CORAL_06_VALUE = '#FFAEB4' as const

/** `#FFDADE` @deprecated Use new token `COLOR_RED_20` */
export const COLOR_CORAL_04 = `var(--${DESIGN_TOKENS_PREFIX}-color-coral-04)` as const
/** @deprecated Use new token `COLOR_RED_20_VALUE` */
export const COLOR_CORAL_04_VALUE = '#FFDADE' as const

/** `#FAF1F3` @deprecated Use new token `COLOR_RED_20` */
export const COLOR_CORAL_02 = `var(--${DESIGN_TOKENS_PREFIX}-color-coral-02)` as const
/** @deprecated Use new token `COLOR_RED_20_VALUE` */
export const COLOR_CORAL_02_VALUE = '#FAF1F3' as const

/** In `light` theme is `#5039D8` */
export const COLOR_ROLE_PRIMARY = `var(--${DESIGN_TOKENS_PREFIX}-color-role-primary)` as const
export const COLOR_ROLE_PRIMARY_VALUE = cssvarValue('color-role-primary')

/** In `light` theme is `#12979E` */
export const COLOR_ROLE_SUCCESS = `var(--${DESIGN_TOKENS_PREFIX}-color-role-success)` as const
export const COLOR_ROLE_SUCCESS_VALUE = cssvarValue('color-role-success')

/** In `light` theme is `#E1AF0F` */
export const COLOR_ROLE_WARNING = `var(--${DESIGN_TOKENS_PREFIX}-color-role-warning)` as const
export const COLOR_ROLE_WARNING_VALUE = cssvarValue('color-role-warning')

/** In `light` theme is `#FF545B` */
export const COLOR_ROLE_ERROR = `var(--${DESIGN_TOKENS_PREFIX}-color-role-error)` as const
export const COLOR_ROLE_ERROR_VALUE = cssvarValue('color-role-error')

/** In `light` theme is `#ffffff` */
export const COLOR_ROLE_BACKGROUND = `var(--${DESIGN_TOKENS_PREFIX}-color-role-background)` as const
export const COLOR_ROLE_BACKGROUND_VALUE = cssvarValue('color-role-background')
